.tabs {
  &_container {
    box-sizing: border-box;
    border-left: 1px solid $geyser;
    border-right: 1px solid $geyser;
    border-bottom: 1px solid $geyser;
    padding-bottom: 10px;

    .msg_panel,
    .note_panel {
      border-left: none;
      border-right: none;
      min-height: 300px;
      max-height: calc(100vh - 500px);
      overflow-y: auto;
    }
  }
}
