.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: $contentWrapperMaxHeight;
  width: 100%;
  opacity: 1;
  background-color: $white;
  z-index: 1501;

  &_spinner {
    z-index: 9999;
    min-width: 100px;
    min-height: 100px;
  }
}