// Body
$body-bg: #f8fafc;

// Typography
$base-font-size: 14px;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;
$athensGray: #f0f2f4;
$azureRadiance: #007bff;
$white: #ffffff;
$black: #000000;
$geyser: #dee2e6;
$iron: #c7cbcf;
$steelBlue: #4a89b3;
$paleSky: #6d757e;
$aquaHaze: #f4f6f9;
$loblolly: #c2c8cf;
$mariner: #2c83bd;
$energyYellow: #f9d055;
$mandy: #de4d4d;
$dodgerBlue: #2286f2;
$malibu: #89bffa;
$pixieGreen: #b6dab4;
$ebonyClay: #1d242b;
$alto: #d7d7d7;

// AdminLte Side Nav Color
$outerSpace: #343a40;

//height
$minHeight: 933px;
$mainHeaderHeight: 50px;
$mainFooterHeight: 45px;
$mainSidebarWidth: 205px;
$contentWrapperMaxHeight: calc(100vh - #{$mainHeaderHeight} - #{$mainFooterHeight});

//shadow
$boxShadow: 0 0 1px rgb(0 0 0 / 13%), 0 1px 3px rgb(0 0 0 / 20%);

.btn-orange {
  background-color: color("orange");
  color: white;
}

.ts-content-wrapper {
  max-height: $contentWrapperMaxHeight;
  overflow-y: scroll;
}

.tsBoxBorder {
  border: 1px solid $paleSky;
}

.tsBoxShadow {
  box-shadow: rgba(149, 157, 165, 0.15) 0px 8px 24px;
  //box-shadow: rgba(0, 0, 0, 0.01) 0px 4px 12px;
}

// Figma Text/Gray
.textGray {
  color: $paleSky !important;
}

.textDark {
  color: $ebonyClay !important;
}

.textDarkAccent {
  color: $outerSpace !important;
}

.textGrayAccent {
  color: $loblolly !important;
}

.bg-textGrayAccent {
  background-color: $loblolly !important;
}

.disabled {
  pointer-events: none;
}

//styleguide
$primaryFont: 'Source Sans Pro', sans-serif !important;

%header-section-title {
  font-size: 24px;
  font-family: $primaryFont;
  font-weight: 600;
}

%header-subSection-title {
  font-size: 18px;
  font-family: $primaryFont;
  font-weight: 600;
}

%header-subSection-subTitle {
  font-size: 18px;
  font-family: $primaryFont;
}

%header-subSection-subTitle-extra {
  font-size: 16px;
  font-family: $primaryFont;
  font-weight: 400;
}

%header-modal {
  font-size: 16px;
  font-family: $primaryFont;
  font-weight: 400;
}

%header-widgets {
  font-size: 0.7rem;
  font-family: $primaryFont;
  font-weight: 700;
}

.pac-container {
  z-index: 100000 !important;
}

.tabs_container {
  border-color: $paleSky !important;
  border-radius: 3px;
  border-top: 1px solid;
}
.nav-tabs {
  border-color: $paleSky !important;
  border-bottom: none;
}
.nav-link.active {
  border-top-color: $paleSky !important;
  border-left-color: $paleSky !important;
  border-right-color: $paleSky !important;
}
.nav-link:hover {
  border-bottom: none !important;
  border-bottom-style: none !important;
}
