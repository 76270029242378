.tcWidget {
  display: flex;
  flex-direction: column;
  min-height: 128px;
  max-height: 128px;
  box-sizing: border-box;
  padding: 8px;
  border-radius: 2px;

  &_header {
    display: flex;
    @extend %header-widgets;
    color: $paleSky;
    align-items: center;
    margin-bottom: 10px;

    .button {
      margin-left: auto;
    }
  }

  &_list {
    min-width: 100%;
    max-width: 100%;
    flex: 1; /* take available space */
    overflow: hidden;
    margin-bottom: 0;
    padding-inline-start: 0;
    @media screen and (min-width: 1280px) {
      padding-inline-start: 2rem;
    }
    @media screen and (min-width: 1920px) {
      padding-inline-start: 5vw;
    }
  }

  &_item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    .night {
      box-sizing: border-box;
      border: 1px solid $paleSky;
      border-radius: 5px;
      padding: 3px 5px 1px;
      @extend %header-widgets;
      color: $paleSky;
    }

    .setting {
      padding: 3px 8px;
      display: flex;
      align-items: center;
      @extend %header-widgets;
      background-color: $dodgerBlue;
      border-radius: 5px;
      color: $white;

      &-air {
        background-color: $paleSky;
      }

      .value {
        margin-left: 5px;
        background-color: $white;
        color: $black;
        padding: 0 5px;
        border-radius: 3px;
        font-size: 0.7rem;
      }
    }

    .type {
      padding: 3px 8px;
      display: flex;
      align-items: center;
      @extend %header-widgets;
      background-color: $malibu;
      border-radius: 5px;
      color: $black;

      &-bipap {
        background-color: $energyYellow;
      }

      &-cpap {
        background-color: $pixieGreen;
      }

      .value {
        margin-left: 5px;
        background-color: $white;
        padding: 0 5px;
        border-radius: 3px;
        font-size: 0.7rem;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .noData {
    @extend %header-widgets;
    text-align: center;
    font-size: 0.75rem;
    font-style: italic;
    color: $paleSky;
  }
}
