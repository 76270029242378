.dWidget {
    display: flex;
    flex-direction: column;
    min-height: 128px;
    max-height: 128px;
    min-width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    padding: 8px 0 0 0;
    border-radius: 2px;

    &_header {
        display: flex;
        @extend %header-widgets;
        color: $paleSky;
        align-items: center;
        margin-bottom: 10px;
        padding: 0 8px;

        .button {
            margin-left: auto;
        }
    }

    &_id {
        text-align: center;
        @extend %header-section-title;
        font-weight: 700;
        margin-bottom: 10px;
    }

    &_status {
        @extend %header-widgets;
        padding: 8px 0;
        text-align: center;
        color: $white;
        background-color: $loblolly;

        &-default {
            background-color: $mariner;
        }

        &-ok {
            background-color: $green;
        }

        &-warn {
            background-color: $energyYellow;
        }

        &-dang {
            background-color: $mandy;
        }

        &comp {
            background-color: $paleSky;
        }
    }
}
