.dashboard {
  // background-color: $white;

  &_header {
    margin-bottom: 12px;
    // border-bottom: 1px solid $geyser;

    &_title,
    &_subtitle {
      display: flex;
      align-items: center;
      box-sizing: border-box;
      color: $white;
      background-color: $steelBlue;
      padding: 6px 16px;

      h2 {
        @extend %header-subSection-subTitle;
      }
    }

    &_subtitle {
      color: $black;
      background-color: $white;

      .holder {
        h3 {
          @extend %header-subSection-title;
        }

        .subTitle {
          &-extra {
            @extend %header-subSection-subTitle-extra;
            color: $outerSpace;
          }
        }
      }
    }

    &_breadcrumb {
      margin-left: auto;
    }
  }
}
