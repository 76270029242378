.tsNav {
    height: 50px;
    display: flex;
    align-items: center;

    &__menu {
        background-color: $ebonyClay;
        height: 100%;
        display: flex;
        align-items: center;
        border-radius: 0% 0% 15% 0%;
    }
}
