#btn-ts {
  @include button-variant($steelBlue, darken($steelBlue, 7.5%), darken($steelBlue, 10%), lighten($steelBlue,5%), lighten($steelBlue, 10%), darken($steelBlue,30%));
}

.btn--disabled-50 {
  opacity: 0.5;
}

.btn--disabled-25 {
  opacity: 0.25;
}