.tsModal {

  &_header {
    background-color: $steelBlue;

    .modal-title, .close {
      color: $white;
    }

    .modal-title {
      @extend %header-modal;
    }

    .close {
      opacity: 1;
        &:hover {
          color: $white;
          opacity: 1 !important;
        }

    }

  }

}