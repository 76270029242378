.msg {
    &_noData {
        padding: 8px;
        text-align: center;
        color: $paleSky;
        border: 1px solid rgba(197, 197, 197, 0.5);
        border-radius: 4px;
        width: 95%;
        margin-left: auto;
        margin-right: auto;
        background-color: rgba(211, 211, 211, 0.1);
    }

    &_panel {
        box-sizing: border-box;
        overflow-y: scroll;
        overflow-x: hidden;
        padding: 5px 0 15px 0;
        border-left: 1px solid $geyser;
        border-right: 1px solid $geyser;
        border-bottom: 1px solid $geyser;
        margin-bottom: 13px;
    }

    &_textarea {
        resize: none;
    }

    &_box {
        margin: 5px 10px 0 10px;

        &_meta {
            display: flex;
            max-width: 350px;
            font-size: 0.9rem;

            &-sender {
                font-weight: bold;
            }

            &-date {
                margin-left: auto;
            }
        }

        &_holder {
            box-sizing: border-box;
            padding: 5px;
            display: flex;
            border: 1px solid $iron;
            border-radius: 5px;
            background-color: $white;
        }

        &_info {
            width: 90%;
            cursor: pointer;

            &-topic {
                font-size: 1rem;
                font-weight: bold;
            }

            &-topic,
            &-desc {
                white-space: nowrap;
                text-overflow: ellipsis;
                width: inherit;
                overflow: hidden;
            }
        }

        &_icon {
            width: 10%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $steelBlue;
        }
    }

    &_bubble {
        margin: 10px;

        &_meta {
            display: flex;
            max-width: 350px;
            font-size: 0.9rem;

            &-date {
                margin-left: auto;
            }
        }

        &_msg {
            max-width: 350px;
            position: relative;
            color: $white;
            background-color: $steelBlue;
            box-sizing: border-box;
            padding: 5px;
            border-radius: 5px;

            &-sent {
                color: $white;
                background-color: $steelBlue;
                &::before {
                    content: "";
                    width: 0;
                    height: 0;
                    border: 6px solid transparent;
                    border-left-color: $steelBlue;
                    position: absolute;
                    left: 100%;
                    top: 45%;
                }
            }

            &-received {
                background-color: $aquaHaze;
                color: $black;
                &::before {
                    content: "";
                    width: 0;
                    height: 0;
                    border: 6px solid transparent;
                    border-right-color: $aquaHaze;
                    position: absolute;
                    right: 100%;
                    top: 45%;
                }
            }
        }
    }
}
