.note {
  &_panel {
    box-sizing: border-box;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 5px 0 15px 0;
    border-left: 1px solid $geyser;
    border-right: 1px solid $geyser;
    border-bottom: 1px solid $geyser;
  }

  &_textarea {
    resize: none;
  }

  &_box {
    box-sizing: border-box;
    margin: 0px 10px 0 10px;
    padding: 5px;

    &_meta {
      display: flex;
      font-size: 0.9rem;

      &-user {
        font-weight: bold;
      }

      &-date {
        margin-left: auto;
        display: flex;
        align-items: center;
      }
    }

    &_text {
      box-sizing: border-box;
      line-height: 1.2rem;
      margin-bottom: 0;
    }

    &_holder {
      box-sizing: border-box;
      padding: 5px;
      border: 1px solid #ffc10780 !important;
      background-color: lightyellow;

      .actions {
        display: flex;
      }
    }
  }

  &-star {
    color: $steelBlue;
    cursor: pointer;

    &_text {
      color: $steelBlue;
      font-family: $primaryFont;
      font-size: 11px;
      margin-left: 3px;
    }
  }

  &-trash {
    color: $red;
    cursor: pointer;
    margin-left: auto;
  }

  &-pinned {
    // background-color: $aquaHaze;
  }
}
