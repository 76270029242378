html {
    font-size: 14px;
}

.navLoader {
    background-image: linear-gradient(
        45deg,
        rgba(255, 255, 255, 0.15) 15%,
        transparent 25%,
        transparent 50%,
        rgba(255, 255, 255, 0.15) 50%,
        rgba(255, 255, 255, 0.15) 75%,
        transparent 75%,
        transparent
    ) !important;
    background-size: 15px 15px;
    animation: progress-bar-stripes 2s linear infinite;
    background-color: #dcdcdc;
}

.wrapper {
    max-height: 100vh !important;
}

.content-wrapper {
    min-height: none !important;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
    background-color: #1d242b !important;
}

.nav-pills .nav-link.active.bg {
    background-color: #466685 !important;
    color: white !important;
}

.nav-pills .nav-link.active.bg .badge {
    border: 1px solid #FFF;
}
/* .white {
    color: white !important;
} */
.font80p {
    font-size: 80%;
}
.font87p {
    font-size: 87%;
}
/* .nav-link {
    padding-top: 0.2em;
} */
.overflow-y-auto {
    overflow-y: auto !important;
}
.overflow-x-hidden {
    overflow-x: hidden !important;
}
.badge {
    font-weight: normal !important;
}

/* .main-sidebar {
    width: 275px;
} */

.sidebar {
    padding-left: 0;
    padding-right: 0;
}

.elevation-4 {
    box-shadow: none !important;
}

.sidebar-mini .main-sidebar .nav-link,
.sidebar-mini-md .main-sidebar .nav-link,
.sidebar-mini-xs .main-sidebar .nav-link {
    width: 100%;
}

.nav-sidebar .nav-item > .nav-link {
    margin-bottom: 0.5rem;
}

.nav-pills .nav-link {
    border-radius: 0;
}

.view-nav {
    padding: 15px;
}

.order-view-container {
    background: none;
}

.main-header {
    min-height: 50px;
    max-height: 50px;
    border-bottom: 1px solid #dee2e6;
}

.main-footer {
    padding: 0 1rem;
}

.order-header {
    background: #4a89b3;
    color: white;
}

.content-header {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

.breadcrumb-item,
.breadcrumb-item.active,
.breadcrumb-item-option {
    color: white;
}

.table.th {
    border: none;
}

.list-patient > a {
    font-size: 1.15rem;
    color: #3a8dbc;
}

.list-dob {
    font-size: 1rem;
    color: #818487;
}

.table-col {
    color: #6f6f6f;
    vertical-align: middle;
    padding: 10px 15px;
}

.table-sm td,
.table-sm th {
    padding: 10px 15px;
}
.table th {
    border-top: none;
}

.breadcrumb-item + .breadcrumb-item::before {
    color: white;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    background-color: #f8f9fa;
}

.sidebar-collapse .badge {
    display: none;
}

/* Order Activity Buttons  */

@media only screen and (max-width: 4000px),
    (min-device-width: 1193px) and (max-device-width: 4000px) {
    .list-activity > button {
        margin-right: 0.5rem;
        margin-bottom: 0;
    }
}

@media only screen and (max-width: 1193px),
    (min-device-width: 992px) and (max-device-width: 1193px) {
    .list-activity > button {
        margin-right: 0;
        margin-bottom: 0.5rem;
    }
}

@media only screen and (max-width: 991px),
    (min-device-width: 864px) and (max-device-width: 991px) {
    .list-activity > button {
        margin-right: 0.5rem;
        margin-bottom: 0;
    }
}

@media only screen and (max-width: 864px),
    (min-device-width: 0px) and (max-device-width: 864px) {
    .list-activity > button {
        margin-right: 0;
        margin-bottom: 0.5rem;
    }
}

/* .tile {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.tile:hover {
    background-color: blue;
}

.tile::before {
    content: "";
    display: block;
} */
.primary-hover:hover {
    background-color: #007bff !important;
    color: white !important;
}

.default-hover:hover {
    background-color: #f8f9fa !important;
    color: #444 !important;
}

.info-hover:hover {
    background-color: #17a2b8 !important;
    color: white !important;
}

.search-results-header {
    margin: 0;
    color: #818487;
    padding: 1rem;
    width: 100%;
}

.card-header {
    color: #3c8dbc;
}

.lead {
    font-weight: 600;
}

.tabs-header {
    color: grey;
}

.tabs-header:hover {
    color: #3c8dbc;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    color: #3c8dbc;
}

.nav-tabs-no-bckg {
    background-color: white !important;
    cursor: pointer;
}
.cursorPointer {
    cursor: pointer !important;
}
.notification-button {
    background-color: white;
    color: #4d8ab1;
    display: inline-block; /* Inline elements with width and height. TL;DR they make the icon buttons stack from left-to-right instead of top-to-bottom */
    position: relative; /* All 'absolute'ly positioned elements are relative to this one */
    padding: 4px 7px; /* Add some padding so it looks nice */
    width: 32px;
    height: 32px;
}

.notification-button__badge {
    background-color: #fa3e3e;
    border-radius: 2px;
    color: white;

    padding: 1px 3px;
    font-size: 8px;

    position: absolute; /* Position the badge within the relatively positioned button */
    top: -0.4rem;
    right: -0.3rem;
}

/* .direct-chat-text::before {
    color: blue;
    border-color: green;
} */

.right .direct-chat-text::after,
.right .direct-chat-text::before {
    border-left-color: #1166ff;
}

.tooltip-test-theme {
    background: indianred;
    color: yellow;
}

.min-height {
    min-height: 933px;
}

/* 
.table {
    display: table;
    margin: auto;
}
.header {
    display: table-header-group;
    font-weight: bold;
}
.row {
    display: table-row;
}
.rowGroup {
    display: table-row-group;
}
.cell {
    padding: 1rem 1rem 1rem 1rem;
    display: table-cell;
    width: 25%;
} */

/* msgs, we need to move this to respective sass file */
.borderRadius25p {
    border-radius: 25px !important;
}

.borderRadius8p {
    border-radius: 8px !important;
}

.borderRadius10p {
    border-radius: 10px !important;
}

.box_APAP {
    color: #333 !important;
    background-color: #89bffa !important;
}
.box_BiPAP {
    color: #333 !important;
    background-color: #fbe39b !important;
}
.box_CPAP {
    color: #333 !important;
    background-color: #b6dab4 !important;
}

.box_NIV {
    color: #6d757e !important;
    background-color: #f4f4f4 !important;
}

.box_ASV {
    color: #6d757e !important;
    background-color: #f4f4f4 !important;
}
.box_ORAL_APP {
    color: #2286f2 !important;
    border-color: #2286f2 !important;
}
.text-left{
    text-align: left;
}

.row_suffix, .row_prefix {
    font-size: 1.3em;
    padding: 0 2px 0 0;
}

.btn-status {
    color: black;
    border-radius: 4px;
    border: 1px solid black;
}

.btn-pending {
  background-color: white;
  color: #343A40;
  border-color: #C2C8CF !important;
  border-width: 1.5px;
}

.btn-pending:hover {
  color: #343A40 !important;
}

.btn-incomplete {
    background-color: #F9D055;
    color: white;
}
.btn-incomplete:hover {
    background-color: #c5a440;
    color: white !important;
}

.btn-provided {
    background-color: #2C83BD;
    color: white;
}

.btn-provided:hover {
    background-color: #246692;
    color: white;
}

.btn-verified, .btn-verified:hover {
    background-color: #31AB4C;
    color: white;
}

.btn-failed, .btn-failed:hover {
    background-color: #DE4D4D;    
    color: white;
}
