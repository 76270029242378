.order_icon-wrapper {
    display: inline-block;
    margin: 0 2px;
    &.order_icon-wraper--pending {
        padding: 4px;
        box-sizing: border-box;
        border-radius: 5px;
        background-color: $energyYellow;
    }
    &.order_icon-wraper--none {
      padding: 4px;
      box-sizing: border-box;
      border-radius: 5px;
      background-color: transparent;
  }
    a:last-child, small:last-child {
        margin-right: 0 !important;
    }
    a, small {
        font-weight: bold;
    }
}
.order_filters {
    input {
        width: 25%;
    }
}