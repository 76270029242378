.nWidget {
  display: flex;
  flex-direction: column;
  min-height: 128px;
  max-height: 128px;
  box-sizing: border-box;
  padding: 8px;
  border-radius: 2px;

  &_header {
    display: flex;
    @extend %header-widgets;
    color: $paleSky;
    align-items: center;
    margin-bottom: 10px;

    .button {
      margin-left: auto;
    }
  }

  &_content {
    min-width: 100%;
    max-width: 100%;
    flex: 1; /* take available space */
    overflow: hidden;
    margin-bottom: 0;

    .note {
      white-space: nowrap;
      text-overflow: ellipsis;
      width: inherit;
      overflow: hidden;
      @extend %header-modal;
      font-size: 14px;
      margin-bottom: 3px;

      span {
        min-width: 0;
      }

      i {
        margin-right: 3px;
        font-size: 8px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .noData {
    @extend %header-widgets;
    text-align: center;
    font-size: 12px;
    font-style: italic;
    color: $paleSky;
  }
}
