.landing {
  display: flex;
  min-height: 100vh;
  flex-direction: column;

  header {
    color: $white;
    background-color: $steelBlue;
    height: 65px;
    display: flex;
    align-items: center;
    padding: 0 15px;
    margin-bottom: 30px;

    h1 {
      background-image: url('/assets/img/logo.svg');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      width: 180px;
      height: 31.5px;
      margin: 0;
    }

    ul {
      margin-left: auto;
      margin-bottom: 0;
      display: flex;

      li {
        margin-right: 10px;

        a {
          color: inherit;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .content {
    flex: 1;
  }

  footer {
    color: $white;
    background-color: $steelBlue;
    width: 100%;
    height: 130px;
    padding: 0 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    p {
      margin-bottom: 3px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .sigHolder {
    display: flex;

    .sigCanvas {
      border: 1px solid #ced4da;
    }

    .buttonHolder {
      display: flex;
      align-items: flex-end;
      padding: 0 0 6px 15px;
    }
  }

  h4 {
    color: $steelBlue;
  }

  .divSize {
    max-width: 500px !important;
  }
}
